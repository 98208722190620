<template>
  <div id="visitorCarApp">
    <van-form @submit="onSubmit">
      <van-cell-group title="车辆信息" inset>
        <van-field
            v-model="carNum"
            name="carNum"
            label="车牌号"
            placeholder="车牌号"
            required
            :formatter="formatter"
            :rules="[{ required: true, message: '请填写正确的车牌号' },{
              pattern: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([DF]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[DF]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/, message: '请填写正确的车牌号'
            }]"
        ></van-field>
        <Picker
            name="typeDic.id"
            label="车辆类型"
            placeholder="请选择车辆类型"
            displayField="name"
            valueField="id"
            :required=true
            v-model:value="this.$data['typeDic.id']"
            :defConfig="{
              portName:'touch.system.Dic.getChildDicsByCode',
              data: {'code': 'VISITOR_CAR_TYPE'},
            }"
            :rules="[{ required: true, message: '请选择车辆类型' }]"
            :canSearch=false
        ></Picker>

        <van-field
            v-model="holder"
            name="holder"
            label="车辆所有人"
            placeholder="车辆所有人"
        ></van-field>
        <van-field
            v-model="brand"
            name="brand"
            label="品牌型号"
            placeholder="品牌型号"
        ></van-field>
        <van-field name="default" label="是否默认车辆">
          <template #input>
            <van-switch name="default" v-model="this.$data.default" size="20"></van-switch>
          </template>
        </van-field>

        <van-field name="drivingLicenseFront" label="行驶证正页">
          <template #input>
            <van-uploader v-model="drivingLicenseFront"
                          :before-read="beforeRead"
                          :after-read="afterRead" max-count="1"></van-uploader>
          </template>
        </van-field>

        <van-field name="drivingLicenseBack" label="行驶证副页">
          <template #input>
            <van-uploader v-model="drivingLicenseBack"
                          :before-read="beforeRead"
                          :after-read="afterRead" max-count="1"></van-uploader>
          </template>
        </van-field>

        <van-field name="operationAttach" label="道路运输证">
          <template #input>
            <van-uploader v-model="operationAttach"
                          :before-read="beforeRead"
                          :after-read="afterRead" max-count="1"></van-uploader>
          </template>
        </van-field>

      </van-cell-group>
      <div style="margin: 4.26667vw;">
        <van-button round block type="primary" native-type="submit" :disabled="btnDisabled" >保存</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {ref} from "vue";
import _ from "lodash";
import Picker from "../../components/Picker";

export default {
  name: "VisitorCar",
  components: {Picker},
  data() {
    return {
      btnDisabled: true,
      id: '',
      carNum: '',
      holder: '',
      brand: '',
      default: false,
      'typeDic.id': '',
      'visitor.id': '',
      showTypePicker: false,
      typeDicColumns: [],
      drivingLicenseFront: [],
      drivingLicenseBack: [],
      operationAttach: []
    };
  },
  created: function () {
    var me = this;
    me.getCarData();
  },
  methods: {
    getCarData: function () {
      var me = this;
      var id = me.$route.params.id;
      if (!_.isEmpty(id)) {
        me.btnDisabled = true;
        var option = {
          portName: 'osp.visitor.VisitorCar.getVisitorCar',
          data: {id: id},
          needLoading: false,
          successCallback: function (data) {
            var entities = data.entities;
            if (!_.isEmpty(entities)) {
              var entity = entities[0];
              var _data = me.$data;
              for (var attrName in entity) {
                if (_data[attrName] != undefined) {
                  _data[attrName] = entity[attrName];
                }
              }
              me.btnDisabled = false;
            }
          }
        }
        this.$sapi.callPort(option);
      } else {
        me.btnDisabled = false;
        var visitor = this.$sapi.storage.getItemJson('visitor');
        me['visitor.id'] = visitor.id;
      }
    },
    formatter: function (val) {
      return val.replace(/\s/g, "").toUpperCase();
    },
    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 200, true);
    },
    afterRead: function (file) {
      this.$sapi.setUploadFileModel(file);
    },
    onTypeConfirm(value) {
      this.typeDicID = value.value;
      this.typeDicName = value.text;
      this.showTypePicker = false;
    },
    onSubmit: function (values) {
      values['id'] = this.id;
      values['visitor.id'] = this['visitor.id'];
      var front = values.drivingLicenseFront[0];
      if (!_.isEmpty(front)) {
        front['fileCode'] = 'front';
      }
      var back = values.drivingLicenseBack[0];
      if (!_.isEmpty(back)) {
        back['fileCode'] = 'back';
      }

      var me = this;
      var option = {
        portName: 'osp.visitor.VisitorCar.saveVisitorCar',
        data: values,
        needLoading: false,
        successCallback: (data) => {
          this.id = data.data.id;
          this.$notify({ type: 'success', message: '保存成功' });
            me.$router.go(-1);
        }
      };
      this.$sapi.callPort(option);
    }
  }
}
</script>

<style scoped>

</style>